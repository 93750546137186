import { Link } from "react-scroll";
import { Link as NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function Navbar() {
  const [blogsDropdown, setBlogsDropdown] = useState(false);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Scroll to top when the route changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  // Function to handle navigation to a section on the home page
  const handleRedirectToHome = (section) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { sectionToScroll: section } });
    }
  };

  return (
    <nav className="absolute top-0 w-[100%] z-50 font-sedan">
      <div className="z-30 w-[100%] bg-opacity-35 bg-gray-800 fixed p-2 px-12 flex justify-between text-lg text-white items-center drop-shadow-xl">
        <NavLink onClick={() => setMobileNavbar(false)} to="/" className="text-3xl font-bold sticky max-md:text-2xl">
          <img className="h-14 rounded-md" src="/images/logo.jpg" alt="Logo" />
        </NavLink>
        <FontAwesomeIcon onClick={() => setMobileNavbar(!mobileNavbar)} className="md:hidden h-8 cursor-pointer" icon={faBars} />
        <div className="max-md:hidden flex justify-center gap-6 items-center text-md">
          {/* Home link brings you to top */}
          <NavLink to="/" className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
            Home
          </NavLink>
          {/* These links will use react-scroll if on the home page */}
          {location.pathname === "/" ? (
            <>
              <Link to="About" smooth={true} duration={500} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                About
              </Link>
              <Link to="Services" smooth={true} duration={500} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                Services
              </Link>
              <Link to="Testimonials" smooth={true} duration={500} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                Testimonials
              </Link>
            </>
          ) : (
            <>
              <div onClick={() => handleRedirectToHome("About")} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                About
              </div>
              <div onClick={() => handleRedirectToHome("Services")} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                Services
              </div>
              <div onClick={() => handleRedirectToHome("Testimonials")} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                Testimonials
              </div>
            </>
          )}

          {/* Blog Dropdown */}
          <div className="relative">
            <h1 onMouseEnter={() => setBlogsDropdown(true)} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
              Blog
            </h1>
            {blogsDropdown && (
              <div onMouseLeave={() => setBlogsDropdown(false)} className="flex flex-col absolute top-[100%] text-lg text-primary bg-gray-700">
                <NavLink to="/blog/marketing-automation" className="hover:bg-complementary duration-300 px-4 p-3">
                  Marketing Automation
                </NavLink>
                <NavLink to="/blog/marketing-attribution" className="hover:bg-complementary duration-300 px-4 p-3">
                  Marketing Attribution
                </NavLink>
                <NavLink to="/blog/growth-stages" className="hover:bg-complementary duration-300 px-4 p-3">
                  Growth Stages
                </NavLink>
                <NavLink to="/blog/marketing-research" className="hover:bg-complementary duration-300 px-4 p-3">
                  Marketing Research
                </NavLink>
              </div>
            )}
          </div>

          {/* Analytics Link */}
          <NavLink to="/analytics" className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
            Analytics
          </NavLink>
        </div>

        <Link to="Contact" smooth={true} duration={500} className="max-md:hidden justify-center items-center flex rounded-sm bg-gray-700 p-3 px-10 hover:scale-110 duration-500 hover:cursor-pointer text-primary">
          Contact
        </Link>
      </div>

      {mobileNavbar && (
        <div className="md:hidden fixed w-[100%] flex flex-col justify-center gap-6 bg-gray-800 h-screen text-white items-center text-md max-md:text-xl">
          <NavLink onClick={() => setMobileNavbar(false)} to="/" className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
            Home
          </NavLink>
          {/* Same conditional logic for mobile */}
          {location.pathname === "/" ? (
            <>
              <Link onClick={() => setMobileNavbar(false)} to="About" smooth={true} duration={500} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                About
              </Link>
              <Link onClick={() => setMobileNavbar(false)} to="Services" smooth={true} duration={500} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                Services
              </Link>
              <Link onClick={() => setMobileNavbar(false)} to="Testimonials" smooth={true} duration={500} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                Testimonials
              </Link>
            </>
          ) : (
            <>
              <div onClick={() => { setMobileNavbar(false); handleRedirectToHome("About"); }} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                About
              </div>
              <div onClick={() => { setMobileNavbar(false); handleRedirectToHome("Services"); }} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                Services
              </div>
              <div onClick={() => { setMobileNavbar(false); handleRedirectToHome("Testimonials"); }} className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
                Testimonials
              </div>
            </>
          )}

          {/* Analytics Link for Mobile */}
          <NavLink onClick={() => setMobileNavbar(false)} to="/analytics" className="p-2 hover:border-b-2 hover:border-solid hover:border-white hover:cursor-pointer hover:justify-start hover:flex">
            Analytics
          </NavLink>

          <Link onClick={() => setMobileNavbar(false)} to="Contact" smooth={true} duration={500} className="justify-center items-center flex rounded-sm bg-gray-700 p-3 px-10 hover:scale-110 duration-500 hover:cursor-pointer text-primary">
            Contact
          </Link>
        </div>
      )}
    </nav>
  );
}
